<!--
 * @Descripttion: 
 * @version: 
 * @Author: 张磊
 * @Date: 2021-05-17 00:47:01
 * @LastEditors: 张磊
 * @LastEditTime: 2021-05-22 10:09:04
 * @FilePath: /官网迭代/src/views/admin/teacher/atte_foundation.vue
-->
<template>
  <div class="foundation_page">
    <div class="tabTit">
      <span class="text">体质测试</span>
    </div>
    <div class="custTab">
      <table class="layui-table" lay-skin="nob">
        <thead>
          <tr>
            <th>学员编号</th>
            <th>姓名</th>
            <th>性别</th>
            <th>身高/cm</th>
            <th>体重/kg</th>
            <th>左眼视力</th>
            <th>右眼视力</th>
            <th>臂展/cm</th>
            <th>肺活量/ml</th>
            <th>胸围/cm</th>
            <th>体脂率/%</th>
            <th>坐姿体前屈/cm</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in list" :key="item.id">
            <td class="">
              S{{ common.PrefixZero(index + 1 + params.page * 10, 3) }}
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.sex}}</td>
            <td >
              <input
                class="inpsty"
                v-model="item.values['1'].value"
                :disabled="item.values['1'].status=== '1'"
                maxlength="8"
                @change="handleInput(item,item.values['1'].value,index,'1')"
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
            </td>
            <td >
              <input
                class="inpsty"
                v-model="item.values['2'].value"
                :disabled="item.values['2'].status=== '1'"
                maxlength="8"
                @change="handleInput(item,item.values['2'].value,index,'2')"
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
            </td>
            <td >
              <input
                class="inpsty"
                v-model="item.values['left'].value"
                :disabled="item.values['left'].status=== '1'"
                maxlength="8"
                @change="handleInput(item,item.values['left'].value,index,'3','left')"
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
            </td>
            <td >
              <input
                class="inpsty"
                v-model="item.values['right'].value"
                :disabled="item.values['right'].status === '1'"
                maxlength="8"
                @change="handleInput(item,item.values['right'].value,index,'3','right')"
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
            </td>
            <td >
              <input
                class="inpsty"
                v-model="item.values['4'].value"
                :disabled="item.values['4'].status === '1'"
                maxlength="8"
                @change="handleInput(item,item.values['4'].value,index,'4')"
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
            </td>
            <td >
              <input
                class="inpsty"
                v-model="item.values['5'].value"
                :disabled="item.values['5'].status=== '1'"
                maxlength="8"
                @change="handleInput(item,item.values['5'].value,index,'5')"
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
            </td>
            <td >
              <input
                class="inpsty"
                v-model="item.values['6'].value"
                :disabled="item.values['6'].status=== '1'"
                maxlength="8"
                @change="handleInput(item,item.values['6'].value,index,'6')"
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
            </td>
            <td >
              <input
                class="inpsty"
                v-model="item.values['7'].value"
                :disabled="item.values['7'].status=== '1'"
                maxlength="8"
                @change="handleInput(item,item.values['7'].value,index,'7')"
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
            </td>
            <td >
              <input
                class="inpsty"
                v-model="item.values['8'].value"
                :disabled="item.values['8'].status=== '1'"
                maxlength="8"
                @change="handleInput(item,item.values['8'].value,index,'8')"
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
              <input v-if="index == (list.length - 1)" style="width:0;height:0;opacity:0;" />
            </td>
          </tr>
        </tbody>
      </table>
      <comPagination :params="params" @changePage="changePage"></comPagination>
    </div>
    <!-- <div class="loadmore loadmore-line loadmore-nodata">
      <span class="loadmore-tips">暂无数据</span>
    </div> -->
    <!-- <div class="pages">{$bt->render()}</div> -->
    <div class="startc" style="margin-top: 20px; margin-bottom: 20px">
      <div class="btn btn_start" @click="submit">完成</div>
    </div>
    <div
      style="
        font-weight: bold;
        color: #cb1b31;
        text-align: right;
        margin-right: 40px;
      "
    >
      成绩只需填写数字，如测试项目为视力，左右眼的两个成绩全部填写完成才会提交，填写所有测试成绩提交后则不可修改成绩
    </div>
  </div>
</template>

<script>
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "foundation",
  components: { comPagination },
  data() {
    return {
      list: [],
      params: {
        page: 0,
        size: 10,
        total: 0,
      },
      cacheName:'',
    };
  },
  created() {},
  mounted() {
    this.cacheName = `physiqueTest_schoolId=${this.$store.state.userInfo.schoolId}_teacherId=${this.$store.state.userInfo.teacherId}_isEnd=${this.$route.query.isEnd}_gradeId=${this.$route.query.gradeId}`;
    this.getList();
  },
  methods: {
    getList() {
      let data = {
        gradeId: this.$route.query.gradeId,
        gradeType: this.$route.query.gradeType,
        isEnd: this.$route.query.isEnd,
        province: this.$store.state.schoolInfo.atteSchool.province,
        schoolId: this.$route.query.schoolId,
        semesterId: this.$route.query.semesterId,
        teacherId: this.$route.query.teacherId,
        // testlevel: this.$route.query.testlevel,
        type: "0",
        stuIds: this.$route.query.stuIds,
        // sportId: this.$route.query.sportId,
      };
      this.api.pes
        .findStuBodyTestByGradeId(this.params, data)
        .then((res) => {
          this.params.total = res.data.total;
          this.list = res.data.list[0].data;
          //处理缓存数据
          this.formatCacheData();
          this.list.forEach((item) => {
            if (item.values['3'].value && item.values['3'].value.indexOf("/") != -1) {
              let a1 = item.values['3'].value.split("/")[0];
              let a2 = item.values['3'].value.split("/")[1];
              this.$set(item.values,'left',{});
              this.$set(item.values,'right',{});
              this.$set(item.values['left'],'value',a1);
              this.$set(item.values['left'],'status',item.values['3'].status);
              this.$set(item.values['right'],'value',a2);
              this.$set(item.values['right'],'status',item.values['3'].status);
            }else{
              this.$set(item.values,'left',{});
              this.$set(item.values,'right',{});
              this.$set(item.values['left'],'value','');
              this.$set(item.values['left'],'status','0');
              this.$set(item.values['right'],'value','');
              this.$set(item.values['right'],'status','0');
            }
            for(let name in item.values){
              if(item.values[name].value == 0) item.values[name].value = "";
            }
          });
          this.params.total = res.data.total;
        });
    },
    toTest() {
      this.$router.push({
        path: "/testCheck",
        query: {
          gradeId: this.$route.query.id,
          isSports: false,
        },
      });
    },
    changePage(val) {
      this.params.page = val;
      this.getList();
    },
    handleInput(item, val, ind, key,eyeItem) {
      //身高
      // var numReg = /^[0-9]*$/;
      // var numRe = new RegExp(numReg);
      var judge = true;
      let fIndex = val.indexOf(".");
      let indexs = val.split(".");
      if (fIndex == 0) {
        judge = false;
      } else if (fIndex > 0 && fIndex == val.length - 1) {
        judge = false;
      } else if (indexs.length > 2) {
        judge = false;
      }

      let eIndex = val.indexOf("-");
      let eIndexs = val.split("-");
      if (eIndex > 0) {
        judge = false;
      } else if (eIndex == 0) {
        if (val.length == 1) {
          judge = false;
        } else if (eIndexs.length > 2) {
          judge = false;
        }
      }
      if (!judge) {
        item.values[key].value = val = "";
        this.$confirm("超出测试范围", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        return;
      }
      if (key == 1 && val) {
        if (parseFloat(val) < 50 || parseFloat(val) > 150) {
          judge = false;
        }
        let pointIndex = val.indexOf(".");
        if (pointIndex > 0 && val.length - pointIndex > 2) {
          item.values[key].value = val = val.substring(0, pointIndex + 2);
          // val.reportOne = item.values[key].value;
        }
        if (!judge) {
          item.values[key].value = val = "";
          this.$confirm("身高超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
        //体重
      } else if (key == 2 && val) {
        if (parseFloat(val) < 5 || parseFloat(val) > 45) {
          judge = false;
        }
        let pointIndex = val.indexOf(".");
        if (pointIndex > 0 && val.length - pointIndex > 2) {
          item.values[key].value = val = val.substring(0, pointIndex + 2);
        }
        if (!judge) {
          item.values[key].value = val = "";
          this.$confirm("体重超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
        // 左眼视力,右眼视力
      } else if (key == '3' && val) {
        if (parseFloat(val) < 4 || parseFloat(val) > 5.3) {
          judge = false;
        }
        let pointIndex = val.indexOf(".");
        if (pointIndex > 0 && val.length - pointIndex > 2) {
          val = val.substring(0, pointIndex + 2);
          this.$set(item.values[eyeItem],'value',val);
        }
        if (!judge) {
          item.values[eyeItem].value = val = "";
          this.$confirm("视力范围为4-5.3之间", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        } else {
          if (item.values.left.value && item.values.right.value) {
            item.values[key].value = val = item.values.left.value + "/" + item.values.right.value;
          } else {
            return;
          }
        }
        // 臂展
      } else if (key == '4' && val) {
        ind = 3;
        if (parseFloat(val) < 20 || parseFloat(val) > 180) {
          judge = false;
        }
        let pointIndex = val.indexOf(".");
        if (pointIndex > 0 && val.length - pointIndex > 2) {
          item.values[key].value = val = val.substring(0, pointIndex + 2);
        }
        if (!judge) {
          item.values[key].value = "";
          val = '';
          this.$confirm("臂展超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
        // 肺活量
      } else if (key == 5 && val) {
        ind = 4;
        if (parseFloat(val) < 100 || parseFloat(val) > 2000) {
          judge = false;
        }
        let pointIndex = val.indexOf(".");
        if (pointIndex > 0) {
          item.values[key].value = val = val.substring(0, pointIndex);
        }
        if (!judge) {
          item.values[key].value = val = "";
          this.$confirm("肺活量超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
        // 胸围
      } else if (key == 6 && val) {
        ind = 5;
        if (parseFloat(val) < 10 || parseFloat(val) > 110) {
          judge = false;
        }
        let pointIndex = val.indexOf(".");
        if (pointIndex > 0 && val.length - pointIndex > 2) {
          item.values[key].value = val = val.substring(0, pointIndex + 2);
        }
        if (!judge) {
          item.values[key].value = val = "";
          this.$confirm("胸围超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
        //体脂率
      } else if (key == 7 && val) {
        ind = 6;
        if (parseFloat(val) < 1 || parseFloat(val) > 40) {
          judge = false;
        }
        let pointIndex = val.indexOf(".");
        if (pointIndex > 0) {
          item.values[key].value = val = val.substring(0, pointIndex);
        }
        if (!judge) {
          item.values[key].value = val = "";
          this.$confirm("体脂率超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
        // 坐卧体前屈
      } else if (key == 8 && val) {
        if (parseFloat(val) < -50 || parseFloat(val) > 50) {
          judge = false;
        }
        let pointIndex = val.indexOf(".");
        if (pointIndex > 0 && val.length - pointIndex > 2) {
          item.values[key].value = val = val.substring(0, pointIndex + 2);
        }
        if (!judge) {
          item.values[key].value = val = "";
          this.$confirm("坐卧体前屈超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
      }
      //缓存数据
      this.cacheData(item, ind, val, key);
      // if (reportOne && val.canPost) {
      //   if (val.canPost) {
      //     // val.canPost = false;
      //     let data = {
      //       type: item.atteStudent.type,
      //       stuId: parseInt(item.atteStudent.id),
      //       teacherId: this.$route.query.teacherId,
      //       testingFoundationId: parseInt(val.testingFoundationId),
      //       testlevel: "1",
      //       semesterId: parseInt(this.$route.query.semesterId),
      //       gradeId: parseInt(item.atteStudent.gradeId),
      //       isEnd: this.$route.query.isEnd,
      //       gradeType: this.$route.query.gradeType,
      //       schoolId: parseInt(this.$route.query.schoolId),
      //       province: this.$store.state.userInfo.provinces,
      //       data: {},
      //     };

      //     data.data[ind + 1] = reportOne;
      //     console.log(data);
      //     // this.api.pes.add(data).then((res) => {
      //     //   val.canPost = true;
      //     //   if (!res.flag) {
      //     //     val.reportOne = "";
      //     //     this.$confirm("上传失败，请重试", "提示", {
      //     //       confirmButtonText: "确定",
      //     //       cancelButtonText: "取消",
      //     //       type: "warning",
      //     //     });
      //     //   }
      //     // });
      //   } else {
      //     this.$confirm("存在上传异常，请重试", "提示", {
      //       confirmButtonText: "确定",
      //       cancelButtonText: "取消",
      //       type: "warning",
      //     });
      //   }
      // }
    },
    //缓存数据
    cacheData(item, index, val, key){
      let exerciseTest = JSON.parse(localStorage.getItem(this.cacheName)) || {};
      if(exerciseTest.data){
        if(exerciseTest.data[`page${this.params.page}`]){ //到page这级
          let flag = false;
          let curInd = 0;
          for(let i =0;i<exerciseTest.data[`page${this.params.page}`].length;i++){
            if(exerciseTest.data[`page${this.params.page}`][i].stuId === item.stuId){
              flag = true;
              curInd = i;
              break;
            }
          }
          if(flag){ //有对应的stuId 到values这级
            exerciseTest.data[`page${this.params.page}`][curInd].values[key] = {value:val,status:'0'};
            if(!val){
              this.$delete(exerciseTest.data[`page${this.params.page}`][curInd].values,key);
            }
          }else{  //没有对应的stuId
            let param = {
              stuId:item.stuId,
            };
            param.values = {};
            param.values[key] = {value:val,status:'0'};
            exerciseTest.data[`page${this.params.page}`].push(param);
          }
        }else{
          let param = {
            stuId:item.stuId,
          };
          param.values = {};
          param.values[key] = {value:val,status:'0'};
          if(!exerciseTest.data[`page${this.params.page}`]){
            exerciseTest.data[`page${this.params.page}`] = [];
          }
          exerciseTest.data[`page${this.params.page}`].push(param);
        }
      }else{
        exerciseTest = {
          teacherId: this.$store.state.userInfo.teacherId,
          schoolId: this.$store.state.userInfo.schoolId,
          testingSportsId: this.$route.query.sportId,
          data:{}
        }
        exerciseTest.data[`page${this.params.page}`] = [];
        let param = {
          stuId:item.stuId,
        };
        param.values = {};
        param.values[key] = {value:val,status:'0'};
        exerciseTest.data[`page${this.params.page}`].push(param);
      }
      localStorage.setItem(this.cacheName,JSON.stringify(exerciseTest))
    },
    //处理缓存数据
    formatCacheData(){
      let exerciseTest = JSON.parse(localStorage.getItem(this.cacheName)) || {};
      if(exerciseTest.teacherId == this.$store.state.userInfo.teacherId && exerciseTest.schoolId == this.$store.state.userInfo.schoolId){
        if(exerciseTest.data[`page${this.params.page}`]){
          this.list.forEach((sItem)=>{
            let cacheData = exerciseTest.data[`page${this.params.page}`].filter((ssItem)=>{
              return ssItem.stuId === sItem.stuId;
            })[0];
            if(cacheData){
              for(let name in sItem.values){
                if(cacheData.values[name]){
                  sItem.values[name].value = cacheData.values[name].value
                }
              }
            }
          })
        }
      }
    },
    submit() {
      let exerciseTest = JSON.parse(localStorage.getItem(this.cacheName)) || {};
      let list = [];
      for(let name in exerciseTest.data){
        list = list.concat(exerciseTest.data[name])
      }
      if(!list.length){
        this.$notice({
          type: "warning",
          message: '您未添加数据,请添加数据后提交',
        }).isShow();
        return;
      }
      let stuId = list.map((item)=>{
        return item.stuId;
      }).join(',');
      let data = {
        gradeId: Number(this.$route.query.gradeId),
        gradeType: this.$route.query.gradeType,
        isEnd: this.$route.query.isEnd,
        province: this.$store.state.schoolInfo.atteSchool.province,
        schoolId: Number(this.$route.query.schoolId),
        semesterId: Number(this.$route.query.semesterId),
        teacherId: this.$route.query.teacherId,
        // testlevel: this.$route.query.testlevel,
        type: "0",
        stuIds: stuId,
        data: list
      };
      console.log(list,'listlist')
      this.api.pes.addBodyData(data).then((res) => {
        if (res.flag) {
          this.$notice({
            type: "success",
            message: res.message,
          }).isShow();
          this.getList();
          localStorage.removeItem(this.cacheName)
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/* 火狐浏览器样式清除 */
input[type="number"] {
  -moz-appearance: textfield;
}
.foundation_page {
  width: 100%;
  .custTab thead th,
  .custTab tbody td {
    text-align: center;
    font-size: 0.24rem !important;
  }
  .inpsty {
    width: 0.8rem;
    height: 0.32rem;
    padding: 0;
    border: solid 1px #ddd;
    line-height: 0.32rem;
    font-size: 0.18rem;
    text-align: center;
  }

  .startc {
    text-align: right;
    padding-right: 0.5rem;
    margin-bottom: 2rem;
  }

  .startc .btn {
    width: 2.9rem;
    line-height: 0.6rem;
    font-size: 0.3rem;
    font-weight: 700;
    color: #fff;
    background: #cc1a30;
    text-align: center;
    display: inline-block;
    cursor: pointer;
  }
  .tabTit {
    min-height: 0.9rem;
    box-sizing: border-box;
    /* text-align: right; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .tabTit .tips {
    font-size: 0.2rem;
    /* position: absolute;
    left: 0; */
  }

  .tabTit .text {
    line-height: 0.3rem;
    font-weight: 700;
    color: #cb1b31;
    font-size: 0.3rem;
    /* padding-right: .5rem;
    text-align: right;
    float: right; */
  }

  .tabTit .searchdiv {
    display: flex;
  }

  .tabTit .searchdiv .btn-search {
    margin-right: 0.2rem;
  }

  .tabTit .btn-add,
  .tabTit .btn-blue {
    padding: 0rem 0.4rem;
    height: 38px;
    line-height: 38px;
    font-size: 0.22rem;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;
    border: none;
  }

  .tabTit .btn-add {
    background: #cb1b31;
    color: #fff;
  }

  .tabTit .btn-blue {
    background: #228cec;
    color: #fff;
  }
  .custTab {
    /* padding: 0rem .5rem; */
    position: relative;
    margin-bottom: 0.4rem;
  }

  .custTab .layui-table {
    padding: 0;
    margin: 0;
  }

  .custTab thead {
    position: relative;
  }

  .custTab thead::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: #f5d1d6;
    height: 0.56rem;
  }

  .custTab thead th {
    background: #cc1a30;
    line-height: 0.3rem;
    font-weight: 700;
    color: #fff;
    font-size: 0.26rem;
    padding: 0.15rem;
  }

  .custTab tbody {
    border-bottom: solid 3px #cc1a30;
  }

  .custTab tbody tr {
    border-bottom: solid 1px #cb1b31;
  }

  .custTab tbody td {
    color: #3e3e3e;
    font-size: 0.22rem;
    line-height: 0.3rem;
    padding: 0.15rem;
  }

  .custTab tbody tr td:last-child {
    padding-right: 0;
  }

  .custTab .btn-start {
    width: 1.28rem;
    height: 0.38rem;
    line-height: 0.38rem;
    background: #b5b5b5;
    color: #fff;
    display: inline-block;
    font-size: 0.22rem;
    text-align: center;
    transition: all 0.2s;
    float: right;
  }
  .custTab .btn-start:hover {
    background: #cc1a30;
  }

  .custTab .btn-play {
    // background: #cc1a30 url(../images/play2.png) 0.2rem center no-repeat;
    background-size: 0.26rem;
    height: 0.4rem;
    line-height: 0.4rem;
    padding-left: 0.6rem;
    width: 0.7rem;
    display: inline-block;
    border-radius: 0.25rem;
    color: #fff;
    font-size: 0.22rem;
  }
}
</style>
